<div class="card card--modal">
  <div class="card-header">
    <div class="card-header__title">{{ 'hotkey-title' | i18next }}</div>
    <div class="card-header__controls">
      <button nxh-button buttonType="icon" (click)="close()">
        <fa-icon icon="times" />
      </button>
    </div>
  </div>
  <div class="card-body">
    <div class="card-body__container">
      <div class="card-body__container card-body__container--has-icon">
        <fa-icon icon="info-circle" />
        <div class="card-body__container__content">
          <div class="data-list data-list--small-key">
            @for (hotkey of hotkeys | keyvalue; track hotkey) {
              <div class="data-list__row">
                <div class="data-list__key">{{ formatHotkey(hotkey.key) }}</div>
                <div class="data-list__value">{{ $any(hotkey.value) | i18next }}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
