import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmDirective, ConfirmModule } from '@nexuzhealth/shared-ui-toolkit/confirm';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgClass } from '@angular/common';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { I18NextModule } from 'angular-i18next';
import { RouterLink } from '@angular/router';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';

/**
 * EllipseActions can be used in your template, where you include the ```nxh-ellipse-actions``` component
 * in your markup, by default, the ellipse container is empty
 * ```
 * <nxh-ellipse-actions></nxh-ellipse-actions>
 * ```
 *
 * You can add content to the ellipse body by providing it in the content of the component
 * ```
 * <nxh-ellipse-actions>
 *    <div>This content will be shown in the ellipse body</div>
 * </nxh-ellipse-actions>
 * ```
 *
 * The ellipse actions has a default edit and delete button, which can be shown or hidden by setting the editOption and deleteOption
 * ```
 * <nxh-ellipse-actions [editOption]="true" [deleteOption]="true"></nxh-ellipse-actions>
 * ```
 *
 * It's also possible to use the edit button as a link by providing a routerLink
 * ```
 * <nxh-ellipse-actions [editOption]="true" [editLink]="['/example']"></nxh-ellipse-actions>
 * ```
 */
@Component({
  selector: 'nxh-ellipsis-actions',
  templateUrl: './ellipsis-actions.component.html',
  styleUrls: ['./ellipsis-actions.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    I18NextModule,
    NgbDropdownModule,
    ConfirmModule,
    IconsModule,
    ButtonModule,
    NgbTooltipModule,
    SharedTechFeatureE2eModule,
  ],
})
export class EllipsisActionsComponent {
  @ViewChild(ConfirmDirective, { static: false }) confirmDirective?: ConfirmDirective;

  @Input() showDropdown = false;
  @Input() loading = false;
  @Input() placeholder = '';
  @Input() container: null | 'body' = null;
  @Input() bypassConfirm = false;
  @Input() smallIcon = false;

  @Input() editOption = false;
  @Input() editLink?: any[] | string | null;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEdit = new EventEmitter();

  @Input() deleteOption = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDelete = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOpenChange = new EventEmitter();

  edit() {
    this.onEdit.emit();
  }

  delete() {
    this.onDelete.emit();
  }

  dropdownChangedEvent(opened: boolean) {
    this.onOpenChange.emit(opened);
    if (opened) {
      return;
    }
  }

  closeConfirm() {
    this.confirmDirective?.close();
  }
}
