<div class="landingpage">
  <img class="logo" src="assets/moapr-logo.png" alt="" />
  <h2 class="title">MOAPR UI Toolkit</h2>
  <nxh-alert type="warning">
    <nxh-alert-description>
      <p>
        We are currently migrating to <a href="https://storybook.nexuzhealth.io" target="_blanc">Storybook</a>. All new
        documentation will be made available there. Existing documentation will be transferred in time with priority for
        the most commonly used components.
      </p>
    </nxh-alert-description>
  </nxh-alert>
  <div class="topics">
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Components</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/alert-message'" routerLinkActive="is-active">Alert messages</a></li>
        <li><a [routerLink]="'/breadcrumb'" routerLinkActive="is-active">Breadcrumbs</a></li>
        <li><a [routerLink]="'/bullet-list'" routerLinkActive="is-active">Bullet list</a></li>
        <li><a [routerLink]="'/button'" routerLinkActive="is-active">Buttons</a></li>
        <li><a [routerLink]="'/card'" routerLinkActive="is-active">Card</a></li>
        <li><a [routerLink]="'/charts/pijnschaal'" routerLinkActive="is-active">Charts</a></li>
        <li><a [routerLink]="'/collapse'" routerLinkActive="is-active">Collapse</a></li>
        <li><a [routerLink]="'/confirm'" routerLinkActive="is-active">Confirm</a></li>
        <li><a [routerLink]="'/counter-bar-card'" routerLinkActive="is-active">Counter bar card</a></li>
        <li><a [routerLink]="'/divider'" routerLinkActive="is-active">Divider</a></li>
        <li><a [routerLink]="'/dropdown'" routerLinkActive="is-active">Dropdown menu</a></li>
        <li><a [routerLink]="'/highlight-block'" routerLinkActive="is-active">Highlight block</a></li>
        <li><a [routerLink]="'/icon-loader'" routerLinkActive="is-active">Icon Loader</a></li>
        <li><a [routerLink]="'/progress-bar'" routerLinkActive="is-active">Progress bar</a></li>
        <li><a [routerLink]="'/status-message'" routerLinkActive="is-active">Status message</a></li>
        <li><a [routerLink]="'/toast'" routerLinkActive="is-active">Toast</a></li>
        <li><a [routerLink]="'task-check'" routerLinkActive="is-active">Tasklist Check</a></li>
      </ul>
    </div>
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Patterns</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/ellipsis-button'" routerLinkActive="is-active">Ellipsis button</a></li>
        <li><a [routerLink]="'/empty-state'" routerLinkActive="is-active">Empty state</a></li>
        <li><a [routerLink]="'/hotkeys'" routerLinkActive="is-active">Hotkeys</a></li>
        <li><a [routerLink]="'/loading-states'" routerLinkActive="is-active">Loading states</a></li>
        <li><a [routerLink]="'/page-layout'" routerLinkActive="is-active">Page layout</a></li>
        <li><a [routerLink]="'/patterns/ellipsis-with-tooltip'" routerLinkActive="is-active">Ellipsis w/tooltip</a></li>
        <li><a [routerLink]="'/scrollspy'" routerLinkActive="is-active">Scrollspy</a></li>
        <li><a [routerLink]="'/tab-nav/tab1'" routerLinkActive="is-active">Tabnav</a></li>
        <li><a [routerLink]="'/tabs'" routerLinkActive="is-active">Tabs</a></li>
        <li><a [routerLink]="'/timeout-state'" routerLinkActive="is-active">Timeout state</a></li>
      </ul>
    </div>
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Forms</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/autosuggest'" routerLinkActive="is-active">Autosuggest</a></li>
        <li>
          <a [routerLink]="'/date-picker'" routerLinkActive="is-active">Date controls (date picker, calendar...)</a>
        </li>
        <li><a [routerLink]="'/dirty-check'" routerLinkActive="is-active">Dirty check</a></li>
        <li><a [routerLink]="'/disable-submit-on-enter'" routerLinkActive="is-active">Disable Submit on Enter</a></li>
        <li><a [routerLink]="'/focus'" routerLinkActive="is-active">Focus</a></li>
        <li><a [routerLink]="'/input-list'" routerLinkActive="is-active">Input list</a></li>
        <li><a [routerLink]="'/lookforward-focus'" routerLinkActive="is-active">Lookforward focus</a></li>
        <li><a [routerLink]="'/phonenumber'" routerLinkActive="is-active">Phonenumber</a></li>
        <li><a [routerLink]="'/radio-checkbox'" routerLinkActive="is-active">Radio and checkboxes (new)</a></li>
        <li><a [routerLink]="'/recurrence'" routerLinkActive="is-active">Recurrence (new)</a></li>
        <li><a [routerLink]="'/selects'" routerLinkActive="is-active">Selects</a></li>
        <li><a [routerLink]="'/timepicker'" routerLinkActive="is-active">Timepicker</a></li>
        <li><a [routerLink]="'/upload'" routerLinkActive="is-active">Upload</a></li>
      </ul>
    </div>
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Data Visualisations</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/detail-bubble'" routerLinkActive="is-active">Detail Bubble</a></li>
        <li><a [routerLink]="'/calendar'" routerLinkActive="is-active">Calendars</a></li>
        <li><a [routerLink]="'/planners'" routerLinkActive="is-active">Planners</a></li>
        <li><a [routerLink]="'/pipes'" routerLinkActive="is-active">Pipes (new)</a></li>
      </ul>
    </div>
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Design Kit</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/grid'" routerLinkActive="is-active">Grid</a></li>
        <li><a [routerLink]="'/helper-classes'" routerLinkActive="is-active">Helper Classes</a></li>
        <li><a [routerLink]="'/theme'" routerLinkActive="is-active">Theme colors</a></li>
        <li><a [routerLink]="'/typography'" routerLinkActive="is-active">Typography</a></li>
      </ul>
    </div>
    <div class="topic">
      <div class="card--compact card">
        <div class="card-body">Guidelines</div>
      </div>
      <ul class="sidebar-menu">
        <li><a [routerLink]="'/page-layout'" routerLinkActive="is-active">Page layout</a></li>
        <li><a [routerLink]="'/settings'" routerLinkActive="is-active">Settings</a></li>
        <li><a [routerLink]="'/timeouts'" routerLinkActive="is-active">Timeouts</a></li>
      </ul>
    </div>
  </div>
</div>
